<template>

  <div class="diagram-label">{{ title }}</div>

</template>

<script>
export default {
  props: {
    title: { type: String, required: true }
  }
}
</script>

<style lang="css" scoped>

.diagram-label {
  background-color: #28bf4f;
  bottom: -0.5rem;
  color: #fff;
  display: inline;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.075em;
  line-height: 1;
  position: relative;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  transform: translateZ(0px);
  border-radius: 2px;
  margin: 0px auto;
}

</style>
