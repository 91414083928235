<template>

  <svg width="20" height="30" viewBox="0 0 20 30" class="data-flow">
    <path d="M10 40 L10 -10" class="data-flow-stream"></path>
  </svg>

</template>

<script>
export default {
}
</script>

<style lang="css" scoped>

.data-flow {
  display: block;
  margin: 0px auto;
}

.data-flow-stream {
  stroke: #f90698;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-dasharray: 0.5, 10;
  animation: 400ms linear 0s infinite normal none running animation-roller;
}

@keyframes animation-roller {
  100% {
    stroke-dashoffset: 10;
  }
}

</style>
