<template>
  <div id="app">

    <div style="max-width: 900px; margin: 0 auto;" class="container">

      <SubHeader title="Automated Text Ad Management">
        <p>Levitate Ad Engine builds high-performing ads from your product inventory. Data driven, scalable text ad automation.</p>
      </SubHeader>

      <Diagram />

      <br /><br />

      <SubHeader title="Trusted by hundreds of European merchants.">
        <p>Wanna give it a try? Contact <a href="#">partner@levitate.se</a> for more information.</p>
      </SubHeader>

    </div>

    <TheFooter />

  </div>
</template>

<script>

import Diagram from './components/Diagram.vue'
import TheFooter from './components/TheFooter.vue'
import SubHeader from '@/components/SubHeader.vue'

export default {
  name: 'App',
  components: {
    Diagram, TheFooter, SubHeader
  }
}
</script>

<style>

html, body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #010932;
  margin-top: 60px;
}
</style>
