<template lang="html">

  <footer>
    <p>© 2010-2020 Levitate AB. Fyristorg 6, 75310 Uppsala Sweden | VAT: SE556932998901 | Org nr. 556932-9989</p>
  </footer>

</template>

<script>
export default {
}
</script>

<style lang="css" scoped>

footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f2f2f2;
  text-align: center;
}

</style>
