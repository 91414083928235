<template>
  <div class="subheader">

    <div class="section">
      <h2>{{ title }}</h2>
      <slot />
    </div>

  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>

.subheader {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  color: #010932;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-size: 2.5em;
  line-height: 0.87273;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Palatino';
}

p {
  font-size: 1.5em;
  line-height: 1.38889;
  margin-bottom: 1.5em;
  font-weight: 300;
  max-width: 900px;
}

</style>
